import { v4 as uuid } from 'uuid';

import { getCookie, isBrowser, setCookie } from './helper';
import { addToDataLayer } from './tagManager';

export const setupVisitorId = () => {
    if (!isBrowser()) {
        return '';
    }
    let visitorId = '';
    try {
        visitorId = getCookie('visitor_id') || '';
    } catch (e) {
        return '';
    }
    if (visitorId !== '') {
        return visitorId;
    }
    visitorId = uuid();
    try {
        setCookie('visitor_id', visitorId);
        addToDataLayer({
            visitorId,
        });
        return visitorId;
    } catch (e) {
        // reportError('Error while setting userId');
    }
    return visitorId;
};
