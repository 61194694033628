'use client';

import React, { useEffect, useState } from 'react';

import 'react-toastify/dist/ReactToastify.min.css';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';

import { QueryClientProvider } from '@tanstack/react-query';
import { hidePageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import {
    ClientApp,
    TLocaleAPI,
    TLocaleSEO,
    TConversionRates,
    NavDepartmentsResponse,
} from '@nm-namshi-frontend/core/types';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import initializeAnalytics from '@nm-namshi-frontend/core/utils/analytics';
import initializeDictionary from '@nm-namshi-frontend/core/translations/initialize';
import { initApiInstance } from '@nm-namshi-frontend/core/api';
import { setupVisitorId } from '@nm-namshi-frontend/core/utils/client';
import QuickBuyModal from '@nm-namshi-frontend/core/page_components/shared/product/QuickBuyModal';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { ConfigContextProvider } from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { addToDataLayer } from '@nm-namshi-frontend/core/utils/tagManager';
import useAuthenticationStore from '@nm-namshi-frontend/core/stores/useAuthenticationStore';
import { COOKIE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';
import StoreClosedModal from '@nm-namshi-frontend/core/page_components/shared/CountrySelection/StoreClosedModal';
import queryClient from '@nm-namshi-frontend/core/queryClient';
import { deleteCookie, getCookie } from '@nm-namshi-frontend/core/utils/helper';
import AuthFlowModal from '@nm-namshi-frontend/core/auth/AuthFlowModal';
import { CustomerMigrationController } from '@nm-namshi-frontend/core/migration/components/CustomerMigrationController';
import useClearGuestAddressKeyCookie from '@nm-namshi-frontend/core/hooks/useClearGuestAddressKeyCookie';
import useInitGeoLocCookies from '@nm-namshi-frontend/core/hooks/useInitGeoLocCookies';
import { AppContextProvider } from '@nm-namshi-frontend/core/contexts/AppContext';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';
import GroupedProductsModal from '@nm-namshi-frontend/core/page_components/shared/product/GroupedProductsModal';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';
import PaymentRetryModal from '@nm-namshi-frontend/core/page_components/shared/paymentRetry/PaymentRetryModal';

const ToastNotification = dynamic(() => import('@nm-namshi-frontend/core/components/ToastNotification'));

type TProps = {
    children: React.ReactNode;
    params: { locale: TLocaleSEO };
    conversionRates: TConversionRates;
    isLoggedInToNamshi: boolean;
    isLoggedInToNoon: boolean;
    navigationData: NavDepartmentsResponse | { error: string; status: number };
};

// This component manages the root layout client side operations that cannot be used within ./layout.tsx
const BigalogRootLayoutClient = ({
    children,
    conversionRates,
    isLoggedInToNamshi,
    isLoggedInToNoon,
    navigationData,
    params,
}: TProps) => {
    const { locale } = params;
    const { pathWithLocale } = useAppPathname();
    const { apiLocale, isoLocale } = getLocaleConfig(locale);
    const { authModal, removeProductforQuickBuy, selectedProductforQuickBuy, setAuthModal } = useLayoutStore();
    const { setIsLoggedInToNamshi, setIsLoggedInToNoon } = useAuthenticationStore();
    const setNavigationData = useDepartmentStoreV2((store) => store.setNavigationData);

    const nLoc = getCookie(COOKIE_ID.NOON_LOCALE);
    if (nLoc) {
        deleteCookie(COOKIE_ID.OLD_NAMSHI_LOCALE);
    }
    const [oldNamshiCookie, setOldNamshiCookie] = useState(nLoc ? '' : getCookie(COOKIE_ID.OLD_NAMSHI_LOCALE));

    initializeDictionary(locale);
    useEffect(() => {
        if (locale) {
            initializeClient();
            addToDataLayer({
                locale,
            });
        }
    }, [locale]);

    useEffect(() => {
        // Ensure that the modal closes on route changes on the client
        setAuthModal({ show: false });
        removeProductforQuickBuy();
        // Hide loading bar after a new url loads
        hidePageLoader();
        setIsLoggedInToNamshi(isLoggedInToNamshi);
        setIsLoggedInToNoon(isLoggedInToNoon);
    }, [pathWithLocale]);

    useEffect(() => {
        // Update store
        if ('error' in navigationData) {
            setNavigationData(null);
        } else {
            setNavigationData(navigationData);
        }
    }, [navigationData]);

    initApiInstance({ app: ClientApp.BIGALOG, locale: apiLocale });

    const initializeClient = async () => {
        const vid = setupVisitorId();
        const { setCookie } = await import('@nm-namshi-frontend/core/utils/helper');
        setCookie(COOKIE_ID.NOON_LOCALE, apiLocale);
        setCookie(COOKIE_ID.OLD_NAMSHI_LOCALE, isoLocale.split('-').join('_'));

        initializeAnalytics(locale, vid);
    };

    // Moving these modals to a layout level + work with zustand
    // Done cuz position:fixed on <Modal /> seems to act wierd with any containers that have CSS Transforms on them eg. nuka-carousels
    const renderQuickBuyModal = () => {
        if (selectedProductforQuickBuy) {
            return (
                <QuickBuyModal
                    isOpen={!!selectedProductforQuickBuy}
                    onClose={removeProductforQuickBuy}
                    product={selectedProductforQuickBuy}
                />
            );
        }
        return null;
    };

    return (
        <AppContextProvider locale={locale} device="desktop">
            <QueryClientProvider client={queryClient}>
                <ConfigContextProvider conversionRates={conversionRates}>
                    <ToastNotification />
                    <AppWrapper apiLocale={apiLocale}>
                        {children}
                        <StoreClosedModal
                            open={!!oldNamshiCookie}
                            onClose={() => {
                                setOldNamshiCookie('');
                                deleteCookie(COOKIE_ID.OLD_NAMSHI_LOCALE);
                            }}
                        />
                        {renderQuickBuyModal()}
                        <GroupedProductsModal />
                        <AuthFlowModal
                            isOpen={authModal.show}
                            onSuccess={authModal.onSuccess}
                            onClose={() => setAuthModal({ show: false })}
                            enableSignupJourney={authModal.enableSignupJourney}
                        />
                        <CustomerMigrationController />
                        <PaymentRetryModal />
                    </AppWrapper>
                </ConfigContextProvider>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
        </AppContextProvider>
    );
};

const AppWrapper: React.FC<{
    children: React.ReactNode;
    apiLocale: TLocaleAPI;
}> = ({ apiLocale, children }) => {
    const router = useRouter();

    const fetchCustomerData = useCustomerStore((store) => store.fetchCustomerData);

    useEffect(() => {
        // Load only client-side
        fetchCustomerData();
    }, []);

    useClearGuestAddressKeyCookie();
    useInitGeoLocCookies({
        onCookiesUpdated: () => {
            initApiInstance({ app: ClientApp.BIGALOG, locale: apiLocale }); // to sync the new cookies
        },
        onInitialize: () => {
            router.refresh();
        },
    });

    return <>{children}</>;
};

export default BigalogRootLayoutClient;
