import { COOKIE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';

import { deleteCookie, setCookie } from './helper';

export const setGeoLocCookies = ({ lat, lng, locId }: { lat: number; lng: number; locId?: string }) => {
    setCookie(COOKIE_ID.LAT, lat);
    setCookie(COOKIE_ID.LNG, lng);

    if (locId) setCookie(COOKIE_ID.LOC_ID, locId);
    else deleteCookie(COOKIE_ID.LOC_ID);
};

export const clearGeoLocCookies = () => {
    deleteCookie(COOKIE_ID.LAT);
    deleteCookie(COOKIE_ID.LNG);
    deleteCookie(COOKIE_ID.LOC_ID);
    deleteCookie(COOKIE_ID.DELIVERY_ZONE_CODE);
    deleteCookie(COOKIE_ID.X_ADDRESS_KEY);
};

export const setDeliveryZoneCode = (code: string) => {
    setCookie(COOKIE_ID.DELIVERY_ZONE_CODE, code);
};
