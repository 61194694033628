import { MAP_CONFIG } from '@nm-namshi-frontend/core/config';
import { LatLngLiteral } from '@nm-namshi-frontend/services';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getCookie } from '@nm-namshi-frontend/core/utils/helper';
import { COOKIE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';
import { setDeliveryZoneCode, setGeoLocCookies } from '@nm-namshi-frontend/core/utils/geoLocCookies';

type TCordinate = {
    coords: { latitude: number; longitude: number };
};

const browserGeoLocPermission = ({ onSuccess }: { onSuccess: (val: LatLngLiteral) => void }) => {
    navigator.geolocation.getCurrentPosition(
        async (pos: TCordinate) => {
            const latLng: LatLngLiteral = {
                lat: pos.coords.latitude * MAP_CONFIG.precision,
                lng: pos.coords.longitude * MAP_CONFIG.precision,
            };
            onSuccess(latLng);
        },
        undefined,
        {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        },
    );
};

const useInitGeoLocCookies = ({
    onCookiesUpdated,
    onInitialize,
}: {
    onCookiesUpdated: () => void;
    onInitialize?: () => void;
}) => {
    const handleInitGeoLocCookies = async () => {
        const { data } = await getDeliveryZoneCode();
        if (data) {
            setDeliveryZoneCode(data);
        }

        onInitialize?.();
    };

    const { refetch: getDeliveryZoneCode } = useQuery(
        [REACT_QUERY_KEYS.GET_DELIVERY_ZONE_CODE],
        () => getApiInstance().deliveryZone.locationDeliveryZone({}),
        {
            enabled: false,
        },
    );

    const { refetch } = useQuery(
        [REACT_QUERY_KEYS.GET_CUSTOMER_ADDRESSES],
        () => getApiInstance().customer.getCustomerAddresses({}),
        {
            onSuccess: (data) => {
                const currentLat = getCookie(COOKIE_ID.LAT);
                const currentLng = getCookie(COOKIE_ID.LNG);
                const deliveryZoneCode = getCookie(COOKIE_ID.DELIVERY_ZONE_CODE);

                const defaultLat = data?.selected?.lat?.toString();
                const defaultLng = data?.selected?.lng?.toString();

                if (!defaultLat || !defaultLng) {
                    getBrowserGeoLoc();
                    return undefined;
                }

                if (defaultLat !== currentLat || currentLng !== defaultLng || !deliveryZoneCode) {
                    setGeoLocCookies({ lat: data?.selected?.lat, lng: data?.selected?.lng, locId: data.selected.id });
                    onCookiesUpdated();

                    return handleInitGeoLocCookies?.();
                }

                return undefined;
            },
            onError: () => {
                getBrowserGeoLoc();
            },
        },
    );

    const getBrowserGeoLoc = () => {
        browserGeoLocPermission({
            onSuccess: ({ lat, lng }) => {
                setGeoLocCookies({ lat, lng });
                onCookiesUpdated();

                const currentLat = getCookie(COOKIE_ID.LAT) as string;
                const currentLng = getCookie(COOKIE_ID.LNG) as string;
                const deliveryZoneCode = getCookie(COOKIE_ID.DELIVERY_ZONE_CODE);

                if (currentLat !== lat.toString() || currentLng !== lng.toString() || !deliveryZoneCode) {
                    return handleInitGeoLocCookies?.();
                }

                return undefined;
            },
        });
    };

    return { reinitialize: refetch };
};

export default useInitGeoLocCookies;
