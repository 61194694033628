import React from 'react';

import { useTranslation } from 'react-i18next';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import getImages from '@nm-namshi-frontend/core/utils/images';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { MigrationDetails } from '@nm-namshi-frontend/services/';
import useWindowDimensions from '@nm-namshi-frontend/core/hooks/useWindowDimensions';
import { SCREEN_SIZES } from '@nm-namshi-frontend/core/constants/uiConstants';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { getExternalHref } from '@nm-namshi-frontend/core/utils/url';
import { ClientApp } from '@nm-namshi-frontend/core/types';
import { NOON_PRIVACY } from '@nm-namshi-frontend/core/config';
import { getPrivacyPolicyUrl } from '@nm-namshi-frontend/core/page_components/shared/footer/LinksGroups';

import styles from './MigrationLearnMoreModal.module.scss';
import { MigratedDataBox } from './MigratedDataBox';
import ModalFooter from './ModalFooter';

type TMigrationLearnMoreModal = {
    isOpen: boolean;
    onClose: () => void;
    migratedData?: MigrationDetails;
    isNewAccount?: boolean;
};

export const MigrationLearnMoreModal = ({ isNewAccount, isOpen, migratedData, onClose }: TMigrationLearnMoreModal) => {
    const { t } = useTranslation();
    const { width: viewportWidth } = useWindowDimensions();
    const { locale } = useAppPathname();
    const { isArabic, seoRegion } = getLocaleConfig(locale);
    const isMobile = viewportWidth <= SCREEN_SIZES.screenMob;

    const congratsImg = isArabic ? getImages().migration.congratsAr : getImages().migration.congratsEn;
    const myAccountLink = isMobile
        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, '/account')
        : getExternalHref(ClientApp.BIGALOG, ClientApp.ACCOUNT, locale, '/');
    const NOON_PRIVACY_LINK = NOON_PRIVACY(locale);
    const NAMSHI_PRIVACY_LINK = getPrivacyPolicyUrl(false, locale, seoRegion);

    return (
        <Modal desktopWidth="800px" containerClass={styles.modalWrapper} open={isOpen}>
            <div className={styles.wrapper}>
                <button
                    type="button"
                    onClick={() => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'mig_learn_more',
                            popupAction: 'x',
                        });
                        onClose();
                    }}
                >
                    <Icon size={30} className={styles.close} name="close" />
                </button>
                <div className={styles.upperSection}>
                    {isMobile && <div className={styles.title}>{t('migration.welcome-to-new-experience')}</div>}
                    <img src={congratsImg} className={styles.congratsImage} />
                </div>
                <div className={styles.mainSection}>
                    {!isMobile && <div className={styles.title}>{t('migration.welcome-to-new-experience')}</div>}
                    <div className={styles.description}>{t('migration.completed.access-with-one-account')}</div>
                    <div className={styles.appsLogosContainer}>
                        <img src={getImages().apps.noon} />
                        <img src={getImages().apps.namshi} />
                        <img src={getImages().apps.food} />
                        <img src={getImages().apps.minutes} />
                    </div>
                    <div style={{ marginTop: 11 }} className={styles.description}>
                        {t('migration.completed.no-more-social-login')}
                    </div>
                    {migratedData && !isNewAccount && <MigratedDataBox migratedData={migratedData} />}
                    <div style={{ marginTop: 11 }}>
                        {t('migration.completed.connect-another-account')}{' '}
                        <AlteredLink
                            locale=""
                            style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}
                            href={myAccountLink}
                            onClick={() => {
                                trackEvent({
                                    event: 'popup',
                                    popupName: 'mig_learn_more',
                                    popupAction: 'account',
                                });
                                onClose();
                            }}
                        >
                            {t('migration.completed.my-account-page')}
                        </AlteredLink>
                    </div>{' '}
                    <div style={{ marginTop: 11 }}>
                        {t('migration.completed.we-process-your-data')}({' '}
                        <AlteredLink
                            locale=""
                            href={NOON_PRIVACY_LINK}
                            style={{ textDecoration: 'underline' }}
                            target="_blank"
                            onClick={() => {
                                trackEvent({
                                    event: 'popup',
                                    popupName: 'mig_learn_more',
                                    popupAction: 'noonprivacy',
                                });
                            }}
                        >
                            {t('noon')}
                        </AlteredLink>{' '}
                        /{' '}
                        <AlteredLink
                            locale=""
                            style={{ textDecoration: 'underline' }}
                            target="_blank"
                            href={NAMSHI_PRIVACY_LINK}
                            onClick={() => {
                                trackEvent({
                                    event: 'popup',
                                    popupName: 'mig_learn_more',
                                    popupAction: 'namshiprivacy',
                                });
                            }}
                        >
                            {t('namshi')}
                        </AlteredLink>{' '}
                        )
                    </div>
                    <ModalFooter popupName="mig_learn_more" />
                </div>
            </div>
        </Modal>
    );
};
