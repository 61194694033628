import { useTranslation } from 'react-i18next';

import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import LearnMoreButton from '@nm-namshi-frontend/core/auth/components/LearnMoreButton';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { NOON_TERMS } from '@nm-namshi-frontend/core/config';
import { TERMS_AND_CONDITIONS } from '@nm-namshi-frontend/core/page_components/shared/footer/LinksGroups';

import styles from './ModalFooter.module.scss';

type TProps = {
    popupName: 'mig_intro' | 'mig_learn_more';
};

const ModalFooter: React.FC<TProps> = ({ popupName }) => {
    const { t } = useTranslation();
    const { locale } = useAppPathname();
    const NOON_TERMS_LINK = NOON_TERMS(locale);
    const NAMSHI_TERMS = TERMS_AND_CONDITIONS(false, locale);

    return (
        <div className={styles.wrapper}>
            <LearnMoreButton ctaName={t('migration.completed.faq')} popupName={popupName} />

            <AlteredLink
                locale={locale}
                target="_blank"
                href={NOON_TERMS_LINK}
                onClick={() => {
                    trackEvent({
                        event: 'popup',
                        popupName,
                        popupAction: 'noontnc',
                    });
                }}
            >
                {t('migration.completed.noon-terms')}
            </AlteredLink>
            <a
                href={NAMSHI_TERMS}
                target="_blank"
                onClick={() => {
                    trackEvent({
                        event: 'popup',
                        popupName,
                        popupAction: 'namshitnc',
                    });
                }}
            >
                {t('migration.completed.namshi-terms')}
            </a>
        </div>
    );
};

export default ModalFooter;
