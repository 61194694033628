import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { usePathname } from 'next/navigation';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import Button from '@nm-namshi-frontend/core/components/Button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import usePerformCheckout from '@nm-namshi-frontend/core/hooks/usePerformCheckout';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './PaymentRetryModal.module.scss';
import ProductImage from '../product/ProductImage';

const SHOWN_IMAGES = 3;

const PaymentRetryModal = () => {
    const { t } = useTranslation();
    const { toggleShowFullScreenLoader } = useLayoutStore();
    const [openModal, setOpenModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const queryClient = useQueryClient();

    const pagePath = usePathname();
    const isOnCheckout = pagePath.includes('/checkout');

    useQuery([REACT_QUERY_KEYS.GET_ACTIVE_SESSION], () => getApiInstance().session.getActiveSession({}), {
        onSuccess: (session) => {
            if (session?.pendingOrder) {
                getOrderSummary(session?.pendingOrder);
            }
        },
        enabled: !isOnCheckout,
    });

    const { data: orderSummary, mutate: getOrderSummary } = useMutation(
        [REACT_QUERY_KEYS.GET_ORDER_SUMMARY],
        (orderNr: string) => {
            toggleShowFullScreenLoader(true);
            return getApiInstance().order.getOrderSummary({
                requestBody: {
                    orderNr,
                },
            });
        },
        {
            onSuccess: () => setOpenModal(true),
            onSettled: () => {
                toggleShowFullScreenLoader(false);
            },
        },
    );

    const { getPaymentIntentStatus } = usePerformCheckout({});

    const close = () => setOpenModal(false);

    const { isLoading: isCancelling, mutate: cancelOrder } = useMutation(
        [REACT_QUERY_KEYS.MARK_ORDER_CANCELLED],
        (orderNr: string) => getApiInstance().order.markOrderCancelled({ orderNr }),
        {
            onSuccess: (res) => {
                toast.success(res.message);
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_ACTIVE_SESSION]);
            },
            onError: (error: any) => {
                if (error?.body?.error) {
                    toast.error(error.body.error);
                }
            },
            onSettled: () => {
                close();
            },
        },
    );

    useEffect(() => {
        if (openModal) {
            trackEvent({
                event: 'popup',
                popupName: 'paymentRetry',
                orderNr: orderSummary?.orderNr,
            });
        }
    }, [openModal]);

    if (!orderSummary) {
        return null;
    }

    const { items } = orderSummary;

    const leftProducts = orderSummary.items.length > SHOWN_IMAGES ? orderSummary.items.length - SHOWN_IMAGES : 0;

    const proceedPayment = () => {
        if (orderSummary?.paymentIntentToken) {
            setIsSubmitting(true);
            trackEvent({
                event: 'popup',
                popupName: 'paymentRetry',
                popupAction: 'completePaymentClick',
                orderNr: orderSummary.orderNr,
            });
            getPaymentIntentStatus(orderSummary.paymentIntentToken);
        }
    };

    const cancel = () => {
        trackEvent({
            event: 'popup',
            popupName: 'paymentRetry',
            popupAction: 'cancelClick',
            orderNr: orderSummary.orderNr,
        });
        cancelOrder(orderSummary.orderNr);
    };

    const loading = isCancelling || isSubmitting;

    return (
        <Modal desktopWidth="560px" showCloseButton={false} open={openModal}>
            <h1 className={styles.title}>{t('payment-retry.do-you-want-complete')}</h1>
            <div className={styles.productImagesWrapper}>
                {items.slice(0, SHOWN_IMAGES).map((item) => (
                    <div key={item.imageKey} className={styles.imageWrapper}>
                        <ProductImage size={IMAGE_SIZE.S} imageKey={item.imageKey} altText={item.title || ''} />
                    </div>
                ))}
                {!!leftProducts && (
                    <span className={styles.moreText}>
                        <span className={styles.plus}>+</span>
                        <span>{t('n-more', { n: leftProducts })}</span>
                    </span>
                )}
            </div>
            <div className={styles.actions}>
                <Button disabled={loading} variant="tertiary" onClick={proceedPayment}>
                    {t('payment-retry.proceed-payment')}
                </Button>
                <Button disabled={loading} variant="secondary" onClick={cancel}>
                    {t('cancel')}
                </Button>
            </div>
        </Modal>
    );
};

export default PaymentRetryModal;
