import React from 'react';

import clsx from 'clsx';

import styles from './VariantPill.module.scss';

type TProps = {
    text: string;
    onSelect: () => void;
    isOutOfStock?: boolean; // @TODO - Mark as mandatory once we get BE to give better out of stock information
    isLowStock?: boolean;
    isSelected: boolean;
};

const VariantPill = ({ isLowStock, isOutOfStock, isSelected, onSelect, text }: TProps) => (
    <button
        className={clsx(
            styles.container,
            isOutOfStock && styles.outOfStock,
            isSelected && styles.selected,
            isLowStock && styles.isLowStock,
        )}
        onClick={onSelect}
        type="button"
    >
        {text}
    </button>
);

export default VariantPill;
