import React from 'react';

import { useTranslation } from 'react-i18next';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import { MigrationDetails } from '@nm-namshi-frontend/services';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './MigratedDataBox.module.scss';
import Price from '../../../components/Price';

type TMigratedData = {
    migratedData: MigrationDetails;
};

export const MigratedDataBox = ({ migratedData }: TMigratedData) => {
    const { t } = useTranslation();

    React.useEffect(
        () => () => {
            trackEvent({
                event: 'popup',
                popupName: 'mig_learn_more',
                popupAction: 'x',
                migBagCnt: migratedData.migratedCartItemsCount,
                migWalletAmt: migratedData.migratedCreditBalance,
                migWishlistCnt: migratedData.migratedWishlistItemsCount,
            });
        },
        [],
    );

    const noData =
        (!migratedData.migratedCreditBalance || migratedData.migratedCreditBalance === 0) &&
        (!migratedData.migratedWishlistItemsCount || migratedData.migratedWishlistItemsCount === 0) &&
        (!migratedData.migratedCartItemsCount || migratedData.migratedCartItemsCount === 0);

    if (noData) return null;

    return (
        <div className={styles.migrationDetailsWrapper}>
            <div className={styles.title}>{t('migration.completed.migration-details-title')}</div>
            <div className={styles.list}>
                {migratedData.migratedCreditBalance && migratedData?.migratedCreditBalance !== 0 ? (
                    <div className={styles.listItem}>
                        <div className={styles.firstSection}>
                            <Icon name="wallet" size={16} />
                            <div>{t('wallet-credits')}</div>
                        </div>
                        <Price price={migratedData.migratedCreditBalance} className={styles.price} />
                    </div>
                ) : null}
                {migratedData?.migratedWishlistItemsCount !== 0 && (
                    <div className={styles.listItem}>
                        <div className={styles.firstSection}>
                            <Icon name="heart" size={16} />
                            <div>{t('wishlist')}</div>
                        </div>
                        <div>{migratedData.migratedWishlistItemsCount}</div>
                    </div>
                )}
                {migratedData?.migratedCartItemsCount !== 0 && (
                    <div className={styles.listItem}>
                        <div className={styles.firstSection}>
                            <Icon name="shoppingBag" size={16} />
                            <div>{t('migration.bag')}</div>
                        </div>
                        <div>{migratedData.migratedCartItemsCount}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
