import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import congratsAr from '@nm-namshi-frontend/core/theme/lotties/congrats_ar.json';
import congratsEn from '@nm-namshi-frontend/core/theme/lotties/congrats_en.json';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import Button from '@nm-namshi-frontend/core/components/Button';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './MigrationWelcomeModal.module.scss';
import ModalFooter from './ModalFooter';

type TWelcomeModal = {
    isOpen: boolean;
    onClose: () => void;
    openLearnMore: () => void;
    isNewAccount?: boolean;
};

export const MigrationWelcomeModal = ({ isNewAccount, isOpen, onClose, openLearnMore }: TWelcomeModal) => {
    const { t } = useTranslation();
    const [showClose, setShowClose] = useState(false);
    const { locale } = useAppPathname();
    const { isArabic } = getLocaleConfig(locale);

    const congratsImg = isArabic ? congratsAr : congratsEn;

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setShowClose(true);
            }, 3000);
        }
    }, []);

    return (
        <Modal desktopWidth="500px" panelStyle={styles.panel} containerClass={styles.modalWrapper} open={isOpen}>
            {showClose && (
                <button
                    type="button"
                    className={styles.close}
                    onClick={() => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'mig_intro',
                            popupAction: 'x',
                        });
                        onClose();
                    }}
                >
                    <Icon size={30} name="close" />
                </button>
            )}
            <div className={styles.wrapper}>
                <div className={styles.upperSection}>
                    <div className={styles.congratsImg}>
                        <Lottie width={300} options={{ animationData: congratsImg, loop: false }} />
                    </div>
                </div>
                <div className={styles.mainSection}>
                    <div className={styles.title}>{t('migration.welcome-to-new-experience')}</div>
                    {isNewAccount ? (
                        <div className={styles.description}>
                            {t('migration.completed.namshi-part-of-noon-new-account')}
                        </div>
                    ) : (
                        <div className={styles.description}>{t('migration.completed.namshi-part-of-noon')}</div>
                    )}

                    <Button
                        onClick={() => {
                            trackEvent({
                                event: 'popup',
                                popupName: 'mig_intro',
                                popupAction: 'awesome',
                            });
                            onClose();
                        }}
                        customStyle={styles.primaryCTA}
                    >
                        {t('migration.completed.awesome')}
                    </Button>
                    <Button
                        onClick={() => {
                            trackEvent({
                                event: 'popup',
                                popupName: 'mig_intro',
                                popupAction: 'learnmore',
                            });
                            openLearnMore();
                        }}
                        customStyle={styles.secondaryCTA}
                        variant="secondary"
                    >
                        {t('migration.completed.learn-more')}
                    </Button>
                    <ModalFooter popupName="mig_intro" />
                </div>
            </div>
        </Modal>
    );
};
