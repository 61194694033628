import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductFullResponse, ProductHeadline, ProductSimpleResponse } from '@nm-namshi-frontend/services';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { getImageUrl, IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import Button from '@nm-namshi-frontend/core/components/Button';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import Skeleton from '@nm-namshi-frontend/core/components/loaders/Skeleton';
import useCatalogStore from '@nm-namshi-frontend/core/stores/useCatalogStore';
import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './GroupedProductsModal.module.scss';
import ProductImage from './ProductImage';
import HorizontalProductBox from './HorizontalProductBox';
import AlteredLink from '../AlteredLink';

const GroupedProductsModal = () => {
    const { t } = useTranslation(['common', 'catalog']);
    const { locale } = useAppPathname();
    const { product, show } = useLayoutStore((s) => s.groupedProductsModal);
    const setGroupedProductsModal = useLayoutStore((s) => s.setGroupedProductsModal);

    const [selectedProduct, setSelectedProduct] = useState<ProductHeadline | null>(product as any as ProductHeadline);

    useEffect(() => {
        if (product && !selectedProduct) {
            setSelectedProduct(product as any as ProductHeadline);
        }
    }, [product]);

    const handleClose = () => {
        setGroupedProductsModal({ show: false, product: null });
        setSelectedProduct(null);
    };

    const isOOS = selectedProduct?.stockInfo?.code === 'out_of_stock';

    return (
        <Modal
            mobileHeight="96%"
            open={show}
            onClose={handleClose}
            isContentCovering
            showAsSidePanel
            footer={
                selectedProduct && (
                    <AlteredLink locale={locale} href={selectedProduct.uri} disabled={isOOS}>
                        <Button variant="tertiary" disabled={isOOS} onClick={handleClose}>
                            {isOOS ? t('catalog:out-of-stock') : t('selectOption')}
                        </Button>
                    </AlteredLink>
                )
            }
        >
            {show && selectedProduct && (
                <GroupedProductsModalContent selectedProduct={selectedProduct} onSelect={setSelectedProduct} />
            )}
        </Modal>
    );
};

const GroupedProductsModalContent: React.FC<{
    selectedProduct: ProductHeadline;
    onSelect: (val: ProductHeadline) => void;
}> = ({ onSelect, selectedProduct }) => {
    const { t } = useTranslation();
    const product = useLayoutStore((s) => s.groupedProductsModal.product);

    const groupName = (product as ProductFullResponse)?.groupName;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <HorizontalProductBox product={selectedProduct} />
                    <div className={styles.itemListTitle}>{groupName || t('options')}</div>
                </div>

                <div className={styles.itemListContainer}>
                    <GroupedProductsList product={product} selectedUri={selectedProduct.uri} onSelect={onSelect} />
                </div>
            </div>
        </div>
    );
};

type TGroupedProductsListProps = {
    product: ProductSimpleResponse | ProductFullResponse | null;
    selectedUri: string;
    onSelect: (val: ProductHeadline) => void;
};

const GroupedProductsList: React.FC<TGroupedProductsListProps> = ({ onSelect, product, selectedUri }) => {
    const sku = product?.parentSku || product?.sku || '';

    const { data, isFetching } = useQuery(
        [REACT_QUERY_KEYS.GET_GROUPED_PRODUCTS, sku],
        () =>
            getApiInstance().product.getGroupedProducts({
                sku,
            }),
        {
            enabled: !product?.groupedProducts,
        },
    );

    const { groupedHasSwatches = false, groupedProducts = [] } = data || (product as ProductFullResponse) || {};

    if (isFetching)
        return (
            <div className={styles.itemList}>
                {[...Array(Math.ceil(8))].map((_, index) => (
                    <div key={`${index + 1}`} className={styles.loader}>
                        <Skeleton height="160px" width="100%" />
                        <Skeleton height="20px" width="100%" />
                    </div>
                ))}
            </div>
        );

    return (
        <div className={styles.itemList}>
            {groupedProducts?.map((item: ProductHeadline) => (
                <GroupedProduct
                    key={item.sku}
                    item={item}
                    onSelect={() => onSelect(item)}
                    selectedUri={selectedUri}
                    isSwatch={groupedHasSwatches}
                />
            ))}
        </div>
    );
};

const GroupedProduct: React.FC<{
    item: ProductHeadline;
    selectedUri: string;
    onSelect: (val: string) => void;
    isSwatch?: boolean;
}> = ({ isSwatch = false, item, onSelect, selectedUri }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { isViewed } = useIsInViewport({ ref });

    const pageType = useCatalogStore((s) => s.pageType);
    const widgetId = useLayoutStore((s) => s.groupedProductsModal.widgetId);

    const { imageKey, parentSku, sku, stockInfo, title, uri } = item;
    const isOOS = stockInfo?.code === 'out_of_stock';

    useEffect(() => {
        if (isViewed) {
            trackEvent({
                event: 'impression_detail',
                sku,
                pageType: pageType as string,
                parentSku,
                imagePath: getImageUrl(imageKey),
                widgetId,
            });
        }
    }, [isViewed]);

    return (
        <button type="button" className={styles.itemWrapper} onClick={() => onSelect(uri)}>
            <div
                ref={ref}
                className={clsx(styles.item, {
                    [styles.outOfStock]: isOOS,
                    [styles.selected]: uri === selectedUri,
                    [styles.swatch]: isSwatch,
                })}
                style={isSwatch ? { backgroundColor: item.namshiSwatch } : {}}
            >
                {!isSwatch && <ProductImage size={IMAGE_SIZE.ML} imageKey={imageKey} altText={title || ''} />}
            </div>
            <div className={styles.title}>{title}</div>
        </button>
    );
};

export default GroupedProductsModal;
