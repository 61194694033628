'use client';

import React, { useState } from 'react';

import { SIGNED_IN_FLOW } from '@nm-namshi-frontend/core/migration/types';
import useAuthenticationStore from '@nm-namshi-frontend/core/stores/useAuthenticationStore';
import useTrackMigration from '@nm-namshi-frontend/core/migration/useTrackMigration';
import VerifyEmailModal from '@nm-namshi-frontend/core/auth/VerifyEmailModal';
import useStartMigration from '@nm-namshi-frontend/core/migration/useStartMigration';
import useInitFlow from '@nm-namshi-frontend/core/migration/useInitFlow';
import { useQueryClient } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import { MigrationCompletedModal } from '../MigrationCompletedModal';

const CustomerMigrationController = () => {
    const queryClient = useQueryClient();
    const { isVerifyEmailOpen, setIsNotClean, setIsVerifyEmailOpen, setV1LoginProvider } = useAuthenticationStore();

    const [flow, setFlow] = useState<SIGNED_IN_FLOW | null>(null);
    const [isNewCustomer, setIsNewCustomer] = useState(false);

    useInitFlow({
        onSuccess: ({ flow: _flow, is_created, is_migrated, login_provider }) => {
            if (_flow === SIGNED_IN_FLOW.CLEAN) {
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_CUSTOMER]);
                setIsNotClean(false);
                trackEvent({
                    event: 'signin',
                    login_method: 'auto',
                } as any);
            }

            if (is_migrated) return;

            setFlow(_flow);
            setIsNewCustomer(is_created);

            switch (_flow) {
                case SIGNED_IN_FLOW.CLEAN:
                    startMigration();
                    break;
                case SIGNED_IN_FLOW.NOT_CLEAN:
                    setIsNotClean(true);
                    setV1LoginProvider(login_provider);
                    break;
                default:
                    break;
            }
        },
    });

    const startMigration = useStartMigration();
    const { data, isComplete } = useTrackMigration();

    if (isComplete) {
        return <MigrationCompletedModal isNewAccount={isNewCustomer} data={data} />;
    }

    if (flow === SIGNED_IN_FLOW.NOT_CLEAN) {
        return (
            <VerifyEmailModal
                isOpen={isVerifyEmailOpen}
                onClose={() => setIsVerifyEmailOpen(false)}
                onSuccess={() => {
                    queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_CUSTOMER]);
                    setIsVerifyEmailOpen(false);
                    setIsNotClean(false);
                    startMigration();
                }}
            />
        );
    }

    return null;
};

export default CustomerMigrationController;
