import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';

import { SIGNED_IN_FLOW } from './types';

const useInitFlow = ({
    onSuccess,
}: {
    onSuccess: ({
        flow,
        is_created,
        is_migrated,
        login_provider,
    }: {
        flow: SIGNED_IN_FLOW;
        is_created: boolean;
        is_migrated: boolean;
        login_provider: string;
    }) => void;
}) => {
    const { locale } = useAppPathname();
    const { apiRegion, lang } = getLocaleConfig(locale);
    const [loggedOut, setLoggedOut] = useState(false);
    const { customerData, errorCustomerData, isLoadingCustomerData } = useCustomerStore((store) => ({
        customerData: store.customerData,
        isLoadingCustomerData: store.isLoadingCustomerData,
        errorCustomerData: store.errorCustomerData,
    }));

    useEffect(() => {
        if ((!customerData || errorCustomerData) && !isLoadingCustomerData) {
            setLoggedOut(true);
        }
    }, [customerData, errorCustomerData, isLoadingCustomerData]);

    useQuery(
        [REACT_QUERY_KEYS.INIT_MIGRATION_FLOW],
        () =>
            getApiInstance().customerMigration.initMigrationFlow({
                requestBody: {
                    country_code: apiRegion,
                    lang,
                },
            }),
        {
            onSuccess,
            enabled: loggedOut,
            onError: (err: any) => {
                const errorText = err?.body?.message;

                if (!errorText) return;

                trackEvent({
                    event: 'error',
                    errorText,
                });
            },
        },
    );
};

export default useInitFlow;
