import React, { useState } from 'react';

import { TrackMigrationStatusResponse } from '@nm-namshi-frontend/services/models/TrackMigrationStatusResponse';

import { MigrationWelcomeModal } from './MigrationWelcomeModal';
import { MigrationLearnMoreModal } from './MigrationLearnMoreModal';

type TMigrationCompletedModal = {
    data?: TrackMigrationStatusResponse;
    isNewAccount?: boolean;
};

const MigrationCompletedModal = ({ data, isNewAccount }: TMigrationCompletedModal) => {
    const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const onClose = () => setIsOpen(false);

    return (
        <>
            <MigrationWelcomeModal
                isOpen={!isLearnMoreOpen && isOpen}
                onClose={onClose}
                openLearnMore={() => setIsLearnMoreOpen(true)}
                isNewAccount={isNewAccount}
            />
            <MigrationLearnMoreModal
                isNewAccount={isNewAccount}
                isOpen={isLearnMoreOpen && isOpen}
                onClose={onClose}
                migratedData={data}
            />
        </>
    );
};

export default MigrationCompletedModal;
