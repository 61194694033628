import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import en_common from './en/common.json';
import en_account from './en/account.json';
import en_catalog from './en/catalog.json';
import en_checkout from './en/checkout.json';
import en_wishlist from './en/wishlist.json';
import en_app from './en/app.json';
import ar_common from './ar/common.json';
import ar_account from './ar/account.json';
import ar_catalog from './ar/catalog.json';
import ar_checkout from './ar/checkout.json';
import ar_wishlist from './ar/wishlist.json';
import ar_app from './ar/app.json';
import { TLanguage, TLocaleSEO } from '../types';

/**
 *
 * @param locale TLocaleSEO
 * @param compatibilityJSON v3 - for app https://www.i18next.com/misc/migration-guide#json-format-v4-pluralization
 */
const initializeDictionary = (locale: TLocaleSEO, compatibilityJSON?: 'v3') => {
    const lng = locale.split('-')[1] as TLanguage; // 'en' | 'ae'

    if (!i18next.isInitialized) {
        i18next.use(initReactI18next).init({
            compatibilityJSON,
            lng,
            debug: false,
            resources: {
                en: {
                    common: en_common,
                    account: en_account,
                    catalog: en_catalog,
                    checkout: en_checkout,
                    wishlist: en_wishlist,
                    app: en_app,
                },

                ar: {
                    common: ar_common,
                    account: ar_account,
                    catalog: ar_catalog,
                    checkout: ar_checkout,
                    wishlist: ar_wishlist,
                    app: ar_app,
                },
            },
            fallbackLng: ['en', 'ar'],
            fallbackNS: 'common',
            returnNull: false,
            interpolation: {
                escapeValue: false, // React handles this already
            },
        });
    }
};

export default initializeDictionary;
