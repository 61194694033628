/* eslint-disable no-nested-ternary */

'use client';

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isEmpty, isUndefined } from 'lodash';

import { Timer } from '@nm-namshi-frontend/services/models/Timer';
import { ProductSimpleResponse, ProductVariant, StockInfo } from '@nm-namshi-frontend/services';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { useQuery } from '@tanstack/react-query';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import LoaderLogo from '@nm-namshi-frontend/core/components/loaders/LoaderLogo';
import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import VariantPill from '@nm-namshi-frontend/core/page_components/productDetailPage/VariantPill';
import LoaderBounce from '@nm-namshi-frontend/core/components/loaders/LoaderBounce';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import ShippingEstimates from '@nm-namshi-frontend/core/page_components/productDetailPage/ShippingEstimates';

import ProductPrice from './ProductPrice';
import ProductImage from './ProductImage';
import styles from './QuickBuyModal.module.scss';
import ConversionCTA from '../../productDetailPage/ConversionCTA';
import WishlistConversionCTA from '../../productDetailPage/WishlistConversionCTA';
import ErrorView from '../error/ErrorView';
import ImageGalleryCarousel from './ImageGalleryCarousel';
import FouponToggle from '../FouponToggle';
import DesktopSizes from './DesktopSizes';
import AlteredLink from '../AlteredLink';
import TimerSale, { TimerSaleVariant } from './TimerSale';

type TProps = {
    isOpen: boolean;
    onClose: () => void;
    product: ProductSimpleResponse;
    onAddedToBag?: (productTitle: string) => void;
};

const QuickBuyModal = ({ isOpen, onAddedToBag, onClose, product }: TProps) => {
    const isMobile = isSchmatalog();

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            desktopHeight="74%"
            desktopMaxHeight="620px"
            desktopWidth="75%"
            desktopMaxWidth="850px"
            mobileHeight="75%"
            containerClass={!isMobile ? styles.modalContent : ''}
        >
            {!!product && (
                <QuickbuyContent product={product} onClose={onClose} onAddedToBag={onAddedToBag} isMobile={isMobile} />
            )}
        </Modal>
    );
};

const QuickbuyContent = ({
    isMobile,
    onAddedToBag,
    onClose,
    product,
}: {
    product: ProductSimpleResponse;
    onClose: () => void;
    onAddedToBag?: (productTitle: string) => void;
    isMobile: boolean;
}) => {
    const { t } = useTranslation(['catalog', 'common']);

    const { locale, pathWithoutLocale } = useAppPathname();
    const { lang } = getLocaleConfig(locale);
    const [isFouponApplied, setIsFouponApplied] = useState(false);
    const [quickBuyProduct, setQuickBuyProduct] = useState<ProductVariant>({
        ...product,
        sku: null as any as string,
    });
    const [quickBuyProductUri, setQuickBuyProductUri] = useState(quickBuyProduct.uri);
    const [isProductGroupSelected, setProductGroupSelected] = useState(false);
    const [isSizeAlertShown, showSizeAlert] = useState(false);

    const {
        data: catalogResponse,
        isError,
        isFetching,
        refetch: refetchCatalog,
    } = useQuery(
        [REACT_QUERY_KEYS.GET_PRODUCT_DETAILS, quickBuyProductUri],
        () =>
            getApiInstance().product.catalog({
                requestBody: {
                    uri: quickBuyProductUri,
                },
            }),
        {
            keepPreviousData: true,
        },
    );
    const { data: customerData } = useQuery([REACT_QUERY_KEYS.GET_CUSTOMER], () =>
        getApiInstance().customer.getCustomer({}),
    );

    const { data: customerAddressesData, isLoading: isCustomerAddressesLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_CUSTOMER_ADDRESSES],
        () => getApiInstance().customer.getCustomerAddresses({}),
        {
            enabled: !!customerData,
        },
    );

    const {
        data: extrasData,
        isFetching: isExtrasLoading,
        refetch: refetchExtras,
    } = useQuery(
        [REACT_QUERY_KEYS.GET_CATALOG_EXTRAS, pathWithoutLocale, customerAddressesData?.selected?.addressKey],
        () =>
            getApiInstance().extras.catalogExtras({
                requestBody: {
                    uri: quickBuyProductUri,
                },
                ...(customerAddressesData?.selected?.addressKey
                    ? { xAddressKey: customerAddressesData?.selected?.addressKey }
                    : {}),
            }),
    );

    if (
        !(
            catalogResponse &&
            'type' in catalogResponse &&
            catalogResponse.type === 'details' &&
            'product' in catalogResponse.data
        )
    ) {
        return (
            <div className={styles.loader}>
                <LoaderBounce size={12} color="theme" />
            </div>
        );
    }

    const {
        brand,
        brandCode,
        discountPercent,
        fouponText,
        groupName,
        groupValue,
        groupedProducts = [],
        imageKeys,
        isGlobal: isGlobalParentLevel,
        isRocket: isRocketParentLevel,
        maxQty,
        normalPrice,
        parentSku,
        salePrice,
        sku,
        tags = {},
        title,
        variantName,
        variants,
    } = catalogResponse.data.product;

    const isDeliverable = extrasData?.extras?.isDeliverable;

    const areAddToBagPrerequisitesLoading = customerData
        ? isCustomerAddressesLoading || isExtrasLoading
        : isExtrasLoading;

    const isAddToBagDisabled = !areAddToBagPrerequisitesLoading && (!maxQty || !isDeliverable);

    // eslint-disable-next-line no-nested-ternary
    const selectedSku = variants?.length === 1 ? product.sku : isProductGroupSelected ? sku : quickBuyProduct.sku;

    // Callbacks
    const onSelectGroupedProduct = (groupProductUri: string) => {
        setProductGroupSelected(true);
        setQuickBuyProductUri(groupProductUri);
        refetchExtras();
    };

    const onSelectVariant = (productVariant: ProductVariant) => {
        setProductGroupSelected(false);
        setQuickBuyProduct(productVariant);
        refetchExtras();
    };

    // Renderers
    const renderLoader = () => {
        if (isFetching) {
            return (
                <div className={styles.loadingContainer}>
                    <LoaderLogo />
                </div>
            );
        }
        return <></>;
    };

    const renderGallery = () => {
        const { top_left = {} } = tags;
        const tagFirstValue = Object.values(top_left)?.[0] as string;
        return (
            imageKeys && (
                <section className={styles.gallery}>
                    <ImageGalleryCarousel
                        imageKeys={imageKeys}
                        badgeText={tagFirstValue}
                        isNavigationCenter
                        altText={title || ''}
                    />
                </section>
            )
        );
    };

    const renderBrandAndTitle = () => {
        if (!brand && !title) return <></>;
        return (
            <div className={styles.brandAndTitleContainer}>
                <div>
                    <AlteredLink locale={locale} href={`/${locale}/${brandCode}`}>
                        {!!brand && <h2>{brand}</h2>}
                    </AlteredLink>
                    {!!title && (
                        <AlteredLink
                            locale={locale}
                            href={`${locale}${quickBuyProductUri}`}
                            className={styles.productTitleContainer}
                        >
                            <span className={styles.productTitle}>{title}</span>
                            <div className={styles.detailsLink}>
                                <span>{t('details')}</span>
                                <Icon name="externalLink" size={20} />
                            </div>
                        </AlteredLink>
                    )}
                </div>
            </div>
        );
    };

    const renderFouponToggle = () => {
        if (!fouponText) {
            return <></>;
        }

        // Managing the foupon state on UI as it will take effect only when sku is added to bag
        return (
            <div className={styles.fouponContainer}>
                <FouponToggle
                    fouponText={fouponText}
                    isActive={isFouponApplied}
                    sku={selectedSku}
                    onExternalFouponToggle={(isChecked) => setIsFouponApplied(isChecked)}
                />
            </div>
        );
    };

    const renderDropDownLabelWithStockInfo = (_title = '', _stockInfo?: StockInfo) => (
        <div className={styles.dropdownLabel}>
            {_title}
            {_stockInfo?.code === 'out_of_stock' && !isMobile && (
                <span style={{ color: _stockInfo.color, fontSize: '12px' }}>{_stockInfo.label}</span>
            )}
        </div>
    );

    const variantDropdownOptions = variants?.map(({ stockInfo: _stockInfo, title: variantTitle, uri: _uri }) => ({
        value: _uri,
        ...(variantTitle && {
            label: renderDropDownLabelWithStockInfo(variantTitle, _stockInfo),
        }),
        stockInfo: { ..._stockInfo },
    }));

    const renderMobileSizes = () => {
        if (isEmpty(variants) || isUndefined(variants)) return null;
        if (variants?.[0]?.title?.toLowerCase() === 'os') return null;

        const selectedProductStockInfo = quickBuyProduct.stockInfo;

        return (
            <div className={styles.sizeGuide}>
                <div className={styles.header}>
                    <h4>{variantName}</h4>
                    {selectedProductStockInfo && (
                        <span
                            className={clsx(
                                styles.stock_label,
                                selectedProductStockInfo.code === 'low_stock' && styles.lowStockLabel,
                            )}
                            style={{ color: selectedProductStockInfo.color }}
                        >
                            {selectedProductStockInfo.label}
                        </span>
                    )}
                </div>
                <div className={styles.horizontalWrapper}>
                    {variants.map((variant) => {
                        const { sku: variantSku, stockInfo: variantStockInfo, title: variantTitle } = variant;

                        let isOOS = false;
                        let isLowStock = false;
                        if (variantStockInfo) {
                            isOOS = variantStockInfo.code === 'out_of_stock';
                            isLowStock = variantStockInfo.code === 'low_stock';
                        }
                        const isSelected = variantSku === selectedSku;

                        return (
                            <VariantPill
                                key={`variantPill-${variantSku}`}
                                text={(variantTitle || '').toLocaleUpperCase()} // #TODO Nipun: Title should not be undefined. Update svc type?
                                isSelected={isSelected}
                                isLowStock={isLowStock}
                                isOutOfStock={isOOS}
                                onSelect={() => {
                                    if (!isOOS && !isSelected) {
                                        onSelectVariant(variant);
                                    }
                                }}
                            />
                        );
                    })}
                </div>
                {isSizeAlertShown && <p className={styles.sizeAlert}>{t('no-size-selected')}</p>}
            </div>
        );
    };

    const renderSizeAndStockLevelSection = () => (
        <div className={styles.sizeAndStockLevelWrapper}>
            {isMobile ? (
                renderMobileSizes()
            ) : (
                <DesktopSizes
                    onSelectVariant={onSelectVariant}
                    sku={selectedSku}
                    variantName={variantName}
                    variants={variants}
                    isSizeAlertShown={isSizeAlertShown}
                />
            )}
        </div>
    );

    const renderGroupedProducts = () => {
        const doesGroupExist = Boolean(groupName && !!groupedProducts?.length);

        if (!doesGroupExist) return <></>;
        return (
            <div className={styles.groupContainer}>
                <h5 className={styles.groupName}>
                    {groupName} :<span className={styles.groupValue}>{groupValue}</span>
                </h5>
                <div className={styles.groupImages}>
                    {groupedProducts.map(
                        ({ imageKey, parentSku: groupItemParentSku, title: prodTitle, uri: groupItemUri }) => (
                            <button
                                key={groupItemUri}
                                type="button"
                                onKeyUp={() => onSelectGroupedProduct(groupItemUri)}
                                className={clsx(
                                    styles.imageWrapper,
                                    groupItemParentSku === parentSku && styles.selected,
                                )}
                                onClick={() => onSelectGroupedProduct(groupItemUri)}
                            >
                                <ProductImage altText={prodTitle || ''} imageKey={imageKey} size={IMAGE_SIZE.S} />
                            </button>
                        ),
                    )}
                </div>
            </div>
        );
    };

    const renderCtas = () => (
        <div className={styles.ctaSection}>
            <ConversionCTA
                analyticsData={{
                    title: `${brand} ${title}`,
                    parentSku,
                    eventLocation: 'quick_buy',
                    isRocket: !!(quickBuyProduct?.isRocket || isRocketParentLevel),
                    isGlobal: quickBuyProduct?.isGlobal || isGlobalParentLevel,
                }}
                sku={selectedSku}
                price={
                    isProductGroupSelected
                        ? salePrice || normalPrice
                        : quickBuyProduct.salePrice || quickBuyProduct.normalPrice
                }
                isAddToBagDisabled={isAddToBagDisabled}
                areAddToBagPrerequisitesLoading={areAddToBagPrerequisitesLoading}
                text={quickBuyProduct.stockInfo?.code === 'out_of_stock' ? t('out-of-stock') : t('add-to-bag-cta')}
                onSuccess={() => {
                    onClose();
                    onAddedToBag?.(title || '');
                }}
                onNoSizeSelected={() => showSizeAlert(true)}
            />

            <WishlistConversionCTA
                analyticsData={{
                    parentSku,
                    price: isProductGroupSelected
                        ? salePrice || normalPrice
                        : quickBuyProduct.salePrice || quickBuyProduct.normalPrice,
                    isRocket: !!(quickBuyProduct?.isRocket || isRocketParentLevel),
                    isGlobal: !!(quickBuyProduct?.isGlobal || isGlobalParentLevel),
                }}
                sku={sku}
                parentSku={parentSku}
            />
        </div>
    );

    const renderMobileImageGallery = () => (
        <section className={styles.productsSection}>
            <div className={styles.productBoxesScroller}>
                <div className={styles.productBoxes}>
                    {imageKeys?.map((imgKey) => (
                        <ProductImage
                            containerClassname={styles.productImageContainer}
                            key={imgKey}
                            altText={title || ''}
                            imageKey={imgKey}
                            size={IMAGE_SIZE.ML}
                        />
                    ))}
                </div>
            </div>
        </section>
    );

    if (isError) {
        return <ErrorView actionText={t('retry')} onAction={refetchCatalog} className={styles.errorContainer} />;
    }

    return (
        <div className={styles.contentContainer}>
            {renderLoader()}
            <div className={clsx(!isMobile && styles.img_gallery_wrapper)}>
                {isMobile ? renderMobileImageGallery() : renderGallery()}
            </div>
            <section className={styles.productInfo}>
                {renderBrandAndTitle()}
                <div className={styles.priceContainer}>
                    <ProductPrice
                        size="x-large"
                        price={isProductGroupSelected ? normalPrice : quickBuyProduct.normalPrice}
                        salePrice={isProductGroupSelected ? salePrice : quickBuyProduct.salePrice}
                        showDiscount
                        discountPercent={isProductGroupSelected ? discountPercent : quickBuyProduct.discountPercent}
                    />
                    <div className={styles.vat}>{t('includeVAT')}</div>
                </div>
                {renderFouponToggle()}
                <ShippingEstimates extrasData={extrasData} isExtrasLoading={isExtrasLoading} />
                {'timer' in quickBuyProduct && (
                    <TimerSale
                        timerSaleConfig={quickBuyProduct.timer as Timer}
                        variant={TimerSaleVariant.banner}
                        lang={lang}
                    />
                )}
                {!!variantDropdownOptions && renderSizeAndStockLevelSection()}
                {renderGroupedProducts()}
                {renderCtas()}
            </section>
        </div>
    );
};

export default QuickBuyModal;
