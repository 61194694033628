import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace-builder/bigalog/app/[locale]/layoutClient.tsx");
;
import(/* webpackMode: "eager" */ "/workspace-builder/core/styles/theme.scss");
;
import(/* webpackMode: "eager" */ "/workspace-builder/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/workspace-builder/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/workspace-builder/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/workspace-builder/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace-builder/node_modules/react-toastify/dist/ReactToastify.min.css");
;
import(/* webpackMode: "eager" */ "/workspace-builder/node_modules/next/font/google/target.css?{\"path\":\"../core/styles/fonts.ts\",\"import\":\"Alexandria\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"display\":\"swap\",\"subsets\":[\"latin\",\"arabic\"],\"fallback\":[\"Tahoma\",\"Arial\",\"sans-serif\"],\"variable\":\"--font-alexandria\"}],\"variableName\":\"alexandriaFontStyle\"}");
;
import(/* webpackMode: "eager" */ "/workspace-builder/node_modules/next/font/google/target.css?{\"path\":\"../core/styles/fonts.ts\",\"import\":\"Bebas_Neue\",\"arguments\":[{\"weight\":[\"400\"],\"display\":\"swap\",\"subsets\":[\"latin\"],\"fallback\":[\"Tahoma\",\"Arial\",\"sans-serif\"],\"variable\":\"--font-bebas\"}],\"variableName\":\"bebasFontStyle\"}");
;
import(/* webpackMode: "eager" */ "/workspace-builder/node_modules/next/font/google/target.css?{\"path\":\"../core/styles/fonts.ts\",\"import\":\"Sofia_Sans\",\"arguments\":[{\"weight\":[\"800\"],\"display\":\"swap\",\"subsets\":[\"latin\"],\"style\":[\"italic\"],\"fallback\":[\"Tahoma\",\"Arial\",\"sans-serif\"],\"variable\":\"--font-sofia-sans\"}],\"variableName\":\"sofiaSansFontStyle\"}");
