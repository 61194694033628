import React, { FunctionComponent as FC, useMemo } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductVariant } from '@nm-namshi-frontend/services/index';

import styles from './DesktopSizes.module.scss';

interface IDesktopSizes {
    onSelectVariant: (variant: ProductVariant) => void;
    setIsSizeGuideModalVisible?: (value: boolean) => void;
    showSizeGuideCta?: boolean;
    sku: string;
    variantName: string | undefined;
    variants: ProductVariant[] | undefined;
    className?: string;
    isSizeAlertShown?: boolean;
}

// eslint-disable-next-line prefer-arrow-callback
const DesktopSizes: FC<IDesktopSizes> = React.memo(function DesktopSizes(props) {
    const {
        className = '',
        isSizeAlertShown = false,
        onSelectVariant,
        setIsSizeGuideModalVisible = () => null,
        showSizeGuideCta,
        sku,
        variantName,
        variants,
    } = props;

    const { t } = useTranslation('catalog');

    const selectedVariant = useMemo(() => variants?.find(({ sku: variantSku }) => variantSku === sku), [variants, sku]);
    const selectedVariantStockInfo = selectedVariant?.stockInfo;

    const VariantOption = ({ variant }: { variant: ProductVariant }) => {
        let isOOS = false;
        let isLowStock = false;
        if (variant?.stockInfo) {
            isOOS = variant.stockInfo.code === 'out_of_stock';
            isLowStock = variant.stockInfo.code === 'low_stock';
        }

        const isSelected = variant.sku === sku;

        return (
            <button
                type="button"
                key={variant.sku}
                onClick={() => {
                    if (!isOOS && !isSelected) {
                        onSelectVariant(variant);
                    }
                }}
                className={clsx(styles.size_variant, {
                    [styles.oos]: isOOS,
                    [styles.lowStock]: isLowStock,
                    [styles.selected]: isSelected,
                })}
            >
                {variant.title}
            </button>
        );
    };

    if (variants?.[0]?.title?.toLowerCase() === 'os') return null;

    return (
        <section className={clsx(styles.size, { [className]: className })}>
            <div className={styles.header}>
                <h4 className={styles.title} data-sizetitle>
                    {variantName}
                </h4>

                <div className={styles.stockAndGuide}>
                    {selectedVariantStockInfo && (
                        <span
                            className={clsx(
                                styles.stock_label,
                                selectedVariantStockInfo.code === 'low_stock' && styles.lowStockLabel,
                            )}
                            style={{ color: selectedVariantStockInfo.color }}
                        >
                            {selectedVariantStockInfo.label}
                        </span>
                    )}
                    {showSizeGuideCta && (
                        <button
                            className={clsx('--namshi-linkAccent', styles.sizeGuideAndStockWarning)}
                            type="button"
                            onClick={() => setIsSizeGuideModalVisible(true)}
                        >
                            {t('show-size-chart')}
                        </button>
                    )}
                </div>
            </div>
            <div className={styles.size_wrapper}>
                {variants?.map((variant) => (
                    <VariantOption key={variant.sku} variant={variant} />
                ))}
            </div>

            {isSizeAlertShown && <p className={styles.sizeAlert}>{t('no-size-selected')}</p>}
        </section>
    );
});

export default DesktopSizes;
