import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { TrackMigrationStatusResponse } from '@nm-namshi-frontend/services';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import useAuthenticationStore from '@nm-namshi-frontend/core/stores/useAuthenticationStore';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import { trackMigrationProgress } from './migrate';

const MAX_POOLING_COUNT = 10;
let poolingCount = 0;

const useTrackMigration = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { clearOngoingMigrationHash, ongoingMigrationHash } = useAuthenticationStore();

    const [isComplete, setIsComplete] = useState(false);
    const [responseData, setResponseData] = useState<TrackMigrationStatusResponse | undefined>();

    useQuery(
        [REACT_QUERY_KEYS.CUSTOMER_MIGRATION, ongoingMigrationHash],
        () => trackMigrationProgress({ migrationHash: ongoingMigrationHash as string }),
        {
            enabled: !!ongoingMigrationHash,
            onSuccess: (response) => {
                setResponseData(response);
                const { status } = response;
                if (status === 'COMPLETED') {
                    queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_ITEMS]);
                    queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_PAGE_CMS_DATA]);
                    queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_CUSTOMER_WALLET]);
                    queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_ACTIVE_SESSION]);

                    clearOngoingMigrationHash();

                    setIsComplete(true);
                    return;
                }

                if (status === 'FAILED') {
                    clearOngoingMigrationHash();
                    toast.error(t('migration.migrate.failure'));
                    return;
                }

                poolingCount += 1;
                if (poolingCount > MAX_POOLING_COUNT) clearOngoingMigrationHash();
            },
            onError: (err: any) => {
                clearOngoingMigrationHash();
                const errorText = err?.body?.message;

                if (!errorText) return;

                trackEvent({
                    event: 'error',
                    errorText,
                });
            },
            refetchInterval: 3000,
            retry: 5,
            retryDelay: 3000,
        },
    );

    return {
        data: responseData,
        isComplete,
    };
};

export default useTrackMigration;
