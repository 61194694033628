import { useEffect } from 'react';

import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';
import { clearGeoLocCookies } from '@nm-namshi-frontend/core/utils/geoLocCookies';

const useClearGuestAddressKeyCookie = () => {
    const errorCustomerData = useCustomerStore((store) => store.errorCustomerData);

    useEffect(() => {
        if (!!errorCustomerData && errorCustomerData.status === 403) {
            clearGeoLocCookies();
        }
    }, [errorCustomerData]);
};

export default useClearGuestAddressKeyCookie;
